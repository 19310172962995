import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import WebFont from 'webfontloader';






function HeroSection() {
  return (
    
    
    <div className='hero-container'>

      <img src='/images/img-3.gif'/>
      <h1>ADVENTURE AWAITS</h1>
      <div className='hero-btns'>

      </div>
    </div>
  );
}

export default HeroSection;