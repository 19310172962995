import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import WebFont from 'webfontloader';


function AboutSection() {
  return (
    
    <div className='hero-container'>
      <h1>Coming Soon...</h1>
      <div className='hero-btns'>

      </div>
    </div>
  );
}

export default AboutSection;