import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import AboutSection from '../About';

function About() {
  return (
    <>
    <AboutSection></AboutSection>
        <Footer />
    </>
  );
}

export default About;