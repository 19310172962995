import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import AboutSection from '../About';

function Services() {
    return (
      <>
  <AboutSection></AboutSection>
        <Footer />
      </>
    );
  }
  
  export default Services;